import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import {
  BadgeAlert,
  BadgeCheck,
  CheckCheck,
  ChevronsDownUp,
  ChevronsUpDown,
  Loader2,
  LocateFixed,
  MoreVertical,
  Package,
  PencilLine,
  Printer,
  RotateCcw,
  Tag,
  Trash2,
} from "lucide-react";
import { observer } from "mobx-react-lite";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { checklistsHooks, projectsHooks } from "../../../api";
import { ICheckInputSingleLineViewModel } from "../../../application/viewModels/CheckInputSingleLineViewModel";
import { IChecklistViewModel } from "../../../application/viewModels/ChecklistViewModel";
import { IChecklistSectionViewModel } from "../../../application/viewModels/SectionViewModel";
import {
  useProjectStore,
  useSignalRStore,
} from "../../../stores/project-store";
import Grid from "../../atoms/Grid";
import TypographyBodyMedium from "../../atoms/TypographyBodyMedium";
import TypographyTitleLarge from "../../atoms/TypographyTitleLarge";
import { SettingsContext } from "../../context/SettingsManager";
import useMediaQuery from "../../hooks/useMediaQuery";
import useZebraLabelPrint from "../../hooks/useZebraLabelPrint";
import { default as Button2 } from "../../molecules/Button";
import UserAvatar from "../../molecules/user-avatar";
import { Badge } from "../../ui/badge";
import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { Toggle } from "../../ui/toggle";
import {
  ChecklistBodyContainerElement,
  ProgressBarElement,
  SignButtonContainerElement,
} from "./styled";

export interface IChecklistProps {
  checklist: IChecklistViewModel;
  serialNumber: string;
  groups: IChecklistSectionViewModel[];
  getPercentage: (filtered?: boolean) => number;

  children(
    groups: IChecklistSectionViewModel[],
    allOpen: boolean,
    trackActiveCheck: boolean,
  ): ReactElement;
}

export const Checklist: React.FC<IChecklistProps> = observer(
  ({ checklist, serialNumber, groups, getPercentage, children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { defaultOpenSections, activeLocationIds } =
      useContext(SettingsContext);
    const [allOpen, setAllOpen] = useState(defaultOpenSections);
    const [trackActiveCheck, setTrackActiveCheck] = useState(true);
    const [locationFilterOpen, setLocationFilterOpen] = useState(false);
    const filteredPercentage = getPercentage(true);
    const unFilteredPercentage = getPercentage();
    const { checkUid } = useParams<{ checkUid: string | undefined }>();
    const hasLocations = checklist.locations.length > 0;
    const { updateSelectedProject, selectedProject } = useProjectStore();

    const { data: projects } = projectsHooks.useGet(
      "/projects/by-inspection/:inspectionIdentifier",
      {
        params: {
          inspectionIdentifier: checklist.uid,
        },
      },
    );

    const x = projects && projects.results && projects.results.length > 0;

    const { data: project } = projectsHooks.useGet(
      "/projects/:projectId",
      {
        params: {
          projectId:
            (projects &&
              projects.results &&
              projects.results[0] &&
              projects.results[0].id) ??
            0,
        },
      },
      { enabled: !!x },
    );

    useEffect(() => {
      if (project) {
        updateSelectedProject(project);
      }
    }, [project]);

    const handleSign = async () => {
      try {
        setLoading(true);
        await checklist.sign();
      } catch (e) {
        alert((e as Error).message);
      }
      setLoading(false);
    };

    const handleSignRedo = async () => {
      if (window.confirm("Signering ongedaan maken?")) {
        await checklist.signRedo();
      }
    };

    const tasksContainerRef = useRef<HTMLDivElement>(null);

    const theme = useTheme();
    const mediumUp = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

    useEffect(() => {
      if (trackActiveCheck) {
        setTimeout(() => {
          document.querySelector("#active-check")?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 10);
      }
    }, [checkUid, trackActiveCheck]);

    useEffect(() => {
      if (trackActiveCheck) {
        setTimeout(() => {
          document.querySelector("#active-check")?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 200);
      }
    }, []);

    const history = useNavigate();

    useEffect(() => {
      if (checklist.uid && checkUid === undefined && mediumUp) {
        const group = checklist.groups.find((group) => {
          if (group.checks.length < 1) {
            return;
          }

          if (activeLocationIds.length < 1) {
            return group;
          }

          const locations = group.checks
            .map((group) => group.locations)
            .flat()
            .map((location) => location.id);

          if (
            activeLocationIds.filter((activeLocationId) =>
              locations.includes(activeLocationId),
            ).length > 0
          ) {
            return group;
          } else {
            return;
          }
        });

        if (group) {
          const checks =
            activeLocationIds.length > 0
              ? group.checks.filter((c) => {
                  if (activeLocationIds.length) {
                    return activeLocationIds.find((activeLocationId) =>
                      c.locations
                        //@ts-ignore
                        .map((l) => l.id)
                        ?.includes(activeLocationId),
                    );
                  } else {
                    return true;
                  }
                })
              : group.checks;

          const firstUnifinishedCheckUid =
            checks.find((c) => !c.finished)?.uid ?? checks[0].uid;

          history(
            {
              pathname: `/checklist/${checklist.uid}/check/${firstUnifinishedCheckUid}`,
              search: "check-popup=open",
            },
            { replace: true },
          );
        }
      }
      if (checklist.uid && checkUid === undefined && !mediumUp) {
        const group = checklist.groups.find((group) => {
          if (group.checks.length < 1) {
            return;
          }

          if (activeLocationIds.length < 1) {
            return group;
          }

          const locations = group.checks
            .map((group) => group.locations)
            .flat()
            .map((location) => location.id);

          if (
            activeLocationIds.filter((activeLocationId) =>
              locations.includes(activeLocationId),
            ).length > 0
          ) {
            return group;
          } else {
            return;
          }
        });

        if (group) {
          const checks =
            activeLocationIds.length > 0
              ? group.checks.filter((c) => {
                  if (activeLocationIds.length) {
                    return activeLocationIds.find((activeLocationId) =>
                      c.locations
                        //@ts-ignore
                        .map((l) => l.id)
                        ?.includes(activeLocationId),
                    );
                  } else {
                    return true;
                  }
                })
              : group.checks;

          const firstUnifinishedCheckUid =
            checks.find((c) => !c.finished)?.uid ?? checks[0].uid;

          history(
            {
              pathname: `/checklist/${checklist.uid}/check/${
                firstUnifinishedCheckUid === undefined
                  ? checklist.groups[0]?.checks[0]?.uid
                  : firstUnifinishedCheckUid
              }`,
              search: "check-popup=false",
            },
            { replace: true },
          );
        }
      }
    }, [checklist]);

    useEffect(() => {
      if (mediumUp && checklist.uid) {
        const group = checklist.groups.find((group) => {
          if (group.checks.length < 1) {
            return;
          }

          if (activeLocationIds.length < 1) {
            return group;
          }

          const locations = group.checks
            .map((group) => group.locations)
            .flat()
            .map((location) => location.id);

          if (
            activeLocationIds.filter((activeLocationId) =>
              locations.includes(activeLocationId),
            ).length > 0
          ) {
            return group;
          } else {
            return;
          }
        });

        if (group) {
          const checks =
            activeLocationIds.length > 0
              ? group.checks.filter((c) => {
                  if (activeLocationIds.length) {
                    return activeLocationIds.find((activeLocationId) =>
                      c.locations
                        //@ts-ignore
                        .map((l) => l.id)
                        ?.includes(activeLocationId),
                    );
                  } else {
                    return true;
                  }
                })
              : group.checks;

          const firstUnifinishedCheckUid =
            checks.find((c) => !c.finished)?.uid ?? checks[0].uid;

          history(
            {
              pathname: `/checklist/${checklist.uid}/check/${
                firstUnifinishedCheckUid === undefined
                  ? checklist.groups[0]?.checks[0]?.uid
                  : firstUnifinishedCheckUid
              }`,
              search: "check-popup=open",
            },
            { replace: true },
          );
        }
      }
    }, [mediumUp]);

    const { connectionStatus } = useSignalRStore();

    const { mutate: mutateDelete } = checklistsHooks.useDelete(
      "/checklists/:checklistUid",
      {
        params: {
          checklistUid: checklist ? checklist.uid : "",
        },
      },
      {
        onSuccess: () => {
          if (selectedProject) {
            history(`/project/${selectedProject.id}`);
          } else {
            history("/");
          }
        },
      },
    );

    const printCheck = checklist?.groups
      ?.find((group) => group.checks.find((c) => c.title.includes(":stempel:")))
      ?.checks.find((c) =>
        c.title.includes(":stempel:"),
      ) as ICheckInputSingleLineViewModel;
    const showPrintButton = !!printCheck && !checklist.signed;
    const { handlePrint, allowPrint } = useZebraLabelPrint();

    return (
      <div className="flex md:bg-surface-highest md:dark:bg-surface-lowest bg-surface-container">
        <div className="flex flex-col w-full rounded-l-2xl">
          <div className="mt-2 py-6 gap-y-4 flex flex-col px-6 bg-surface-container dark:bg-surface-container 1mb-6 1border rounded-xl sm:ml-0 ml-2 mr-2">
            {!checklist.signed && unFilteredPercentage >= 100 && (
              <SignButtonContainerElement className="hidden bg-background">
                <Grid padded>
                  <TypographyTitleLarge>Je bent klaar!</TypographyTitleLarge>
                  <TypographyBodyMedium>
                    Alle checks zijn afgerond, de checklist kan worden
                    ondertekend.
                  </TypographyBodyMedium>

                  <Button2 disabled={loading} onClick={handleSign}>
                    <div className="flex items-center justify-center">
                      {loading && (
                        <Icon
                          path={mdiLoading}
                          size={0.8}
                          className="animate-spin mr-3"
                        />
                      )}
                      Ondertekenen
                    </div>
                  </Button2>
                </Grid>
              </SignButtonContainerElement>
            )}
            <div className="flex justify-between gap-2 mb-2">
              <div className="flex items-center gap-x-2">
                <div className="flex items-center">
                  <DropdownMenu>
                    <DropdownMenuContent className="w-56 bg-background">
                      <DropdownMenuItem
                        className="text-red-400"
                        onClick={() => {
                          const percentage = checklist.getPercentage();

                          if (percentage === 0) {
                            if (
                              confirm(
                                "Weet je zeker dat je deze checklist wilt verwijderen?",
                              )
                            ) {
                              mutateDelete(undefined);
                            }
                          } else {
                            alert(
                              "Je kan alleen lege checklists verwijderen (checklists waar het percentage 0 is)",
                            );
                          }
                        }}
                      >
                        <Trash2 className="mr-2 h-4 w-4" />
                        <span>Verwijderen</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                    <DropdownMenuTrigger className="" asChild>
                      <div className="mr-2">
                        <MoreVertical className="w-4 h-4" />
                      </div>
                    </DropdownMenuTrigger>
                  </DropdownMenu>

                  <div className="flex items-center gap-x-3">
                    <span className="text-3xl">Checklist</span>

                    {selectedProject && showPrintButton && (
                      <div
                        onClick={() => handlePrint(true, printCheck)}
                        className="cursor-pointer px-1.5 py-1 border w-full rounded-lg justify-between dark:bg-surface-container bg-surface-high relative flex items-center h-fit"
                      >
                        <div className="h-full mr-1.5">
                          <BadgeCheck
                            className={
                              allowPrint
                                ? "text-green-400 w-4 h-4"
                                : "text-neutral-400 w-4 h-4"
                            }
                          />
                        </div>
                        <span className="w-full leading-none flex items-center justify-center rounded-b-lg text-center dark:bg-surface-high bg-surface-low text-xs text-muted-foreground">
                          {/* <span className="truncate overflow-hidden w-12 ">Klokvel</span> */}
                          <Printer className="w-4 h-4" />
                        </span>
                      </div>
                    )}
                    {selectedProject && showPrintButton && (
                      <div
                        onClick={() => handlePrint(false, printCheck)}
                        className="cursor-pointer border px-1.5 py-1 h-fit w-full rounded-lg justify-between dark:bg-surface-container bg-surface-high relative flex items-center"
                      >
                        <div className="h-full mr-1.5">
                          <BadgeAlert
                            className={
                              allowPrint
                                ? "text-red-400 w-4 h-4"
                                : "text-neutral-400 w-4 h-4"
                            }
                          />
                        </div>
                        <span className="w-full leading-none flex items-center justify-center rounded-b-lg text-center dark:bg-surface-high bg-surface-low text-xs text-muted-foreground">
                          {/* <span className="truncate overflow-hidden w-12 ">Klokvel</span> */}
                          <Printer className="w-4 h-4" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {/* {connectionStatus === "connected" && (
                  <span className="text-xs rounded-md px-2 py-1 bg-green-400 text-black font-medium">
                    SignalR: verbonden
                  </span>
                )}
                {connectionStatus === "disconnected" && (
                  <span className="text-xs rounded-md px-2 py-1 bg-orange-400 text-black font-medium">
                    SignalR: niet verbonden
                  </span>
                )} */}
              </div>
              <div className="gap-2 mb-2 hidden lg:flex">
                <Badge className="text-base px-3 bg-[#cac4d0] ">
                  <Package className="w-4 h-4 mr-1" />
                  <p className="truncate overflow-hidden block max-w-[64px]">
                    {serialNumber}
                  </p>
                </Badge>
                <Badge className="text-base px-3 bg-[#cac4d0]  truncate overflow-hidden pr-4">
                  <Tag className="w-4 h-4 mr-1" />
                  <p className="truncate overflow-hidden block max-w-[64px]">
                    {checklist.title}
                  </p>
                </Badge>
              </div>
              {/* <Label text={serialNumber} icon={mdiPackageVariantClosed} />
            <Label text={checklist.title} icon={mdiLabelOutline} /> */}

              {/* {checklist.signed && (
              <Label
                text="Ondertekend"
                deletable
                icon={mdiDraw}
                onDelete={handleSignRedo}
              />
            )} */}
            </div>
            {/* <TypographyLabelLarge>Productchecklist</TypographyLabelLarge> */}

            <div className="flex items-center">
              <span className="text-lg mr-2 font-semibold block text-[#cac4d0]">
                {filteredPercentage}%
              </span>
              <ProgressBarElement
                progress={isNaN(filteredPercentage) ? 0 : filteredPercentage}
              />
            </div>
          </div>

          {!checklist.signed && unFilteredPercentage >= 100 && (
            <div className="pr-2 pt-2 sm:pl-0 pl-2 col-span-3 z-0">
              <div className="relative flex justify-between rounded-lg bg-green-400 z-30 w-full bottom-0 left-0 py-2 items-center px-2">
                <span className="hidden sm:flex items-center pl-2 text-sm text-black font-semibold">
                  {t("inspectionPage.allChecksFinished")}
                </span>
                <Button
                  disabled={loading}
                  onClick={handleSign}
                  size={"sm"}
                  variant={"outline"}
                  className="w-full sm:w-fit text-black ml-auto 1rounded-lg 1py-0 1h-fit rounded-full whitespace-nowrap border-2"
                >
                  {loading ? (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  ) : (
                    <CheckCheck className="w-4 h-4 mr-2" />
                  )}
                  {t("inspectionPage.signChecklist")}
                </Button>
                <img
                  style={{
                    position: "absolute",
                    left: 44,
                    top: -14,
                    zIndex: 99999,
                    width: 20,
                    transform: "rotate(0deg)",
                  }}
                  src="/Polygon 2 copy.svg"
                />
              </div>
            </div>
          )}

          {checklist.signed && unFilteredPercentage >= 100 && (
            <div className="pr-2 pt-2 sm:pl-0 pl-2 col-span-3">
              <div className="relative flex justify-between rounded-lg bg-[#cac4d0] z-30 w-full bottom-0 left-0 py-2 items-center px-2">
                <span className="flex items-center pl-2 text-sm text-black font-semibold">
                  {/* 👍 */}
                  {/* <div>
              </div> */}
                  <PencilLine className="text-blackw-4 h-4 mr-2" />
                  {t("inspectionPage.signedBy")}:
                  <div className="ml-1.5">
                    <UserAvatar left id={checklist.acceptedBy?.value} />
                  </div>
                </span>
                <Button
                  onClick={() => {
                    if (
                      confirm(
                        "Weet je zeker dat je het aftekenen van deze checklist ongedaan wilt maken?",
                      )
                    ) {
                      checklist.signRedo();
                    }
                  }}
                  size={"sm"}
                  variant={"outline"}
                  className="text-black ml-auto 1rounded-lg 1py-0 1h-fit rounded-full whitespace-nowrap border-2"
                >
                  <RotateCcw className="w-4 h-4 mr-2" />
                  {t("inspectionPage.unSign")}
                </Button>
                <img
                  style={{
                    position: "absolute",
                    left: 44,
                    top: -14,
                    zIndex: 99999,
                    width: 20,
                    transform: "rotate(0deg)",
                  }}
                  src="/Polygon 2.svg"
                />
              </div>
            </div>
          )}

          <div className="md:pr-2 md:py-2">
            <div
              ref={tasksContainerRef}
              className={`hide-scrollbar md:bg-surface-container md:dark:bg-surface-container md:rounded-xl md:overflow-auto ${
                unFilteredPercentage >= 100
                  ? "md:h-[calc(100vh-222px)] h-[calc(100vh-262px)]"
                  : "md:h-[calc(100vh-162px)] h-[calc(100vh-202px)]"
              }`}
            >
              <div className="md:bg-surface-container md:dark:bg-surface-container w-full h-fit pl-6 pr-2 pt-2 items-center grid grid-cols-2 ">
                <div className="flex items-center gap-x-2">
                  <span className="text-xl leading-none font-medium">
                    {t("inspectionPage.tasks")}
                  </span>
                </div>
                <div className="ml-auto flex space-x-1.5 p-1.5 rounded-lg bg-surface-highest dark:bg-surface-lowest">
                  <Toggle
                    pressed={allOpen}
                    onPressedChange={(pressed) => setAllOpen(pressed)}
                    className="h-fit p-2 bg-background data-[state=on]:bg-[#cac4d0] group data-[state=on]:text-black text-muted-foreground group:text-primary"
                  >
                    <ChevronsUpDown className="w-4 h-4 duration-100 ease-out group-data-[state=on]:hidden group-data-[state=off]:block" />
                    <ChevronsDownUp className="w-4 h-4 duration-100 ease-out group-data-[state=on]:block group-data-[state=off]:hidden" />
                  </Toggle>

                  <Toggle
                    pressed={trackActiveCheck}
                    onPressedChange={(pressed) => setTrackActiveCheck(pressed)}
                    className="h-fit p-2 bg-background data-[state=on]:bg-[#cac4d0] data-[state=on]:text-black text-muted-foreground group:text-primary"
                  >
                    <LocateFixed className="w-4 h-4 duration-100 ease-out" />
                  </Toggle>
                </div>
              </div>
              <ChecklistBodyContainerElement className="pb-32 md:pb-2">
                <Grid spacing={0} item>
                  {children(groups, allOpen, trackActiveCheck)}
                </Grid>
              </ChecklistBodyContainerElement>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
